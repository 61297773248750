import React from "react";
import Select from "react-select";
import { Style } from "react-style-tag";
const fontOptions = require("./fontOptions.json");
;

export default function SimpleFontSelector({ value, onChange }) {
  const selectedFont = value || fontOptions[0]; // Set a default value if `value` is undefined

  const colourStyles = {
    option: (styles, { data }) => ({
      ...styles,
      fontFamily: data.label,
      color: "black",
      fontSize: "14px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: "8px 10px"
    }),
    singleValue: (styles) => ({
      ...styles,
      fontFamily: selectedFont.label, // Use `selectedFont` to avoid undefined errors
      color: "black",
      fontSize: "14px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }),
    control: (styles) => ({
      ...styles,
      width: 200,
      minHeight: "35px",
      borderColor: "#ccc",
      boxShadow: "none",
      ":hover": {
        borderColor: "#888"
      }
    })
  };

  return (
    <div>
      <Select
        value={selectedFont}
        onChange={onChange}
        options={fontOptions}
        styles={{ ...colourStyles, fontFamily: selectedFont.label }}
        menuShouldScrollIntoView={false}
      />
      {fontOptions.map((font) => (
        <Style key={font.label}>{`
          @font-face {
            font-family: Open Sans;
          }
        `}</Style>
      ))}
    </div>
  );
}
