// src/components/panels/TextPanel.js

import React from 'react';
import { MdPlusOne } from "react-icons/md";

const TextPanel = ({ textObjects, addText }) => {


  return (
    <div className="mt-2 p-2 border border-gray-300 bg-white w-full shadow-md rounded-lg">
      <div className="space-y-4 overflow-y-auto" style={{ maxHeight: "40vh" }}>
        {textObjects.map((object, index) => (
          <div
            key={index}
            className="cursor-pointer flex flex-col bg-white hover:bg-yellow-100 rounded-sm"
            onClick={() => {
              console.log("Object", object.canvas);
              object.canvas.setActiveObject(object);
            }}
          >
            <p className="text-base p-2 font-medium text-gray-800">{object.text}</p>
            {index !== textObjects.length - 1 && <hr className="mt-4 border-t border-gray-300" />}
          </div>
        ))}
      </div>
      <div className="mt-4 flex justify-center">
        <button
          onClick={() => addText("Sample Text")}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 transition duration-150 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-400"
        >
          <MdPlusOne className="text-lg" />
          Add text
        </button>
      </div>
    </div>
  );
};

export default TextPanel;
