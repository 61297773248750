import { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

const useGroups = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchGroups = () => {


    setLoading(true);
    axios.get(`/users/groups/`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //update the template 
  const uploadCsv = (formData) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    setLoading(true);
    axios.post(`/users/groups/upload_csv`,
      formData,
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          // setBgImage(response.data.url);
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }



  //update the template 
  const update = ({ id = null, data }) => {

    if (!id) {
      return;
    }
    setLoading(true);
    axios.patch(`/users/certificates/${id}`,
      { data },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Create a new certificate
  const create = ({ name, certificate_id, description }) => {
    setLoading(true);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    axios.post("/users/groups",
      {
        name,
        description,
        certificate_id,
      },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 201) {

          // Redirect to the dashboard
          window.location.href = `/users/groups/${response.data.id}`;
        }
      }).
      catch((error) => {
        //Go through the errors and display them
        if (error &&
          error.response &&
          error.response.data &&
          error.response.data.errors) {
          //Iterate through the errors and display them
          error.response.data.errors.forEach((error) => {
            toast.error(error, {
              position: "bottom-center",
              draggable: false,
              autoClose: 5000,
            });

          });


        }
        console.log(error.response.data.errors);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  return {
    groups,
    create,
    loading,
    update,
    fetchGroups,
    uploadCsv
  };
}

export default useGroups;  