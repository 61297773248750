// src/components/utils/useCanvasHandlers.js

import { useEffect } from 'react';
const fabric = require('fabric');

const useCanvasHandlers = ({
  editor,
  setTextObjects,
  setDynamicTextObjects,
  setCurrentObject,
  fetchTemplates,
  bgImage,
  props,
  debouncedUpdate,
  update,
  setCurrentTemplates,
  templates,
}) => {
  const handleTemplateClick = (template) => {
    loadFromJSON(template.data);
  };

  const loadFromJSON = async (jsonInput) => {
    await editor.canvas.loadFromJSON(jsonInput);
    editor.canvas.renderAll();
  };

  const handleSendToBack = () => {
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      editor.canvas.sendObjectBackwards(activeObject);
      editor.canvas.renderAll();
    }
  };

  const handleBringToFront = () => {
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      editor.canvas.bringObjectForward(activeObject);
      editor.canvas.renderAll();
    }
  };

  const handleDelete = () => {
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      editor.canvas.remove(activeObject);
      editor.canvas.renderAll();
    }
  };

  const handleCopy = async () => {
    const activeObject = editor.canvas.getActiveObject();
    const cloneObject = await activeObject.clone();
    cloneObject.set({ left: activeObject.left + 10, top: activeObject.top + 10 });
    editor.canvas.add(cloneObject);
    editor.canvas.setActiveObject(cloneObject);
  };

  const addSvgImage = async (svg) => {
    const { objects, options } = await fabric.loadSVGFromURL(svg.image);
    const svgGroup = fabric.util.groupSVGElements(objects, options);
    svgGroup.set('fill', '#000000');
    editor.canvas.add(svgGroup);
    editor.canvas.centerObject(svgGroup);
  };

  const handleBgSelection = (image) => {
    if (image) {
      const bgImage = fabric.Image.fromURL(image, (img) => {
        img.id = "backgroundImage";
        img.selectable = false;
        img.evented = false;
        img.width = editor.canvas.width;
        img.height = editor.canvas.height;
        editor.canvas.add(img);
        editor.canvas.sendObjectToBack(img);
        editor.canvas.renderAll();
        alert("HELLO WORLD")
        canvas.fire('background:changed', { type: 'image', value: img });

      });
    }
  };

  const handleBackgroundImageUpload = ({ target: { files } }) => {
    const file = files[0];
    if (!file) return;
    if (!file.type.startsWith("image/")) {
      console.error("Please upload a valid image file.");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", props.id);
      uploadBg(formData);
    };
    reader.readAsDataURL(file);
  };

  const addText = (text) => {
    editor.canvas.discardActiveObject();
    const textObject = new fabric.IText(text, {
      fontFamily: "cinzel",
      fontSize: 30,
      fill: "#000000",
    });
    textObject.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: true,
      br: true,
      tl: true,
      tr: true,
    });
    editor.canvas.calcOffset();
    editor.canvas.centerObject(textObject);
    editor.canvas.add(textObject);
    editor.canvas.renderAll();
  };

  const addDynamicText = (name) => {
    if (name === "") {
      alert("Please enter a valid variable name");
      return;
    }
    editor.canvas.discardActiveObject();
    const textObject = new fabric.IText(`[${name}]`, {
      fontFamily: "Roboto",
      fontSize: 30,
      fill: "#000000",
      dynamic: true,
      fieldName: name,
      id: name,
      editable: false,
    });
    textObject.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: true,
      br: true,
      tl: true,
      tr: true,
    });
    editor.canvas.calcOffset();
    editor.canvas.centerObject(textObject);
    editor.canvas.add(textObject);
  };

  const handleOnReady = (canvas) => {
    canvas.enableRetinaScaling = false;
    canvas.preserveObjectStacking = true;
    canvas.setWidth(1122);
    canvas.setHeight(794);
    canvas.backgroundColor = '#F6F6F4';
    canvas.loadFromJSON(props.certificate.data, () => {
      setTimeout(() => canvas.renderAll(), 50);
    });
    fabric.devicePixelRatio = 1;
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);

    canvas.on('mouse:down', (event) => {
      const clickedObject = canvas.findTarget(event.e);
      if (clickedObject) {
        setCurrentObject(clickedObject);
      } else {
        setCurrentObject(null);
      }
    });

    canvas.on('object:modified', () => {
      canvas.calcOffset();
      const data = canvas.toObject(['id', 'selectable', 'evented', 'dynamic', 'editable']);
      update({ id: props.id, data });
    });

    canvas.on('object:added', (e) => {
      const obj = e.target;
      obj.lockUniScaling = true;
      if (obj.type === 'i-text' && obj.dynamic !== true) {
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: true,
          br: true,
          tl: true,
          tr: true,
        });
        setTextObjects((prev) => [...prev, obj]);
      } else if (obj.type === 'i-text' && obj.dynamic === true) {
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: true,
          br: true,
          tl: true,
          tr: true,
        });
        setDynamicTextObjects((prev) => [...prev, obj]);
      }
      const data = canvas.toObject(['id', 'selectable', 'evented', 'dynamic', 'editable']);
      update({ id: props.id, data });
    });
  };

  useEffect(() => {
    setCurrentTemplates([...templates]);
  }, [templates]);

  return {
    handleTemplateClick,
    loadFromJSON,
    handleSendToBack,
    handleBringToFront,
    handleDelete,
    handleCopy,
    addSvgImage,
    handleBgSelection,
    handleBackgroundImageUpload,
    addText,
    addDynamicText,
    handleOnReady,
  };
};

export default useCanvasHandlers;
