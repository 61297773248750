// src/components/panels/SmartTextPanel.js

import React from 'react';
import { FiPlus } from 'react-icons/fi';

const SmartTextPanel = ({
  dynamicTextObjects,
  addDynamicText,
  dynamicText,
  setDynamicText,
}) => {
  return (
    <div className="mt-2 p-4 border border-gray-300 bg-white w-full max-w-lg mx-auto shadow-md rounded-lg">
      <div className="space-y-0 overflow-y-auto" style={{ maxHeight: '40vh' }}>
        {dynamicTextObjects.map((textObject, index) => (
          <div
            key={index}
            className="cursor-pointer flex flex-col mt-2"
            onClick={() => {
              textObject.canvas.setActiveObject(textObject);
            }}
          >
            <p className="text-base font-medium text-gray-800">{textObject.text}</p>
            {index !== dynamicTextObjects.length - 1 && (
              <hr className="mt-4 border-t border-gray-300" />
            )}
          </div>
        ))}
      </div>
      <form
        className="flex bg-blue-200 w-30 mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          addDynamicText(dynamicText);
        }}
      >
        <input
          value={dynamicText}
          type="text"
          placeholder="Enter text"
          className="p-2 w-9/12 text-black border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={(e) => {
            setDynamicText(e.target.value);

            const regex = /^[a-zA-Z_\s]*$/;
            if (regex.test(e.target.value)) {
              setDynamicText(e.target.value);
            } else {
              alert("Only alphabets and underscore are allowed");
            }
          }}
        />
        <button
          className="inline-flex w-3/12 items-center justify-center px-3 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <FiPlus className="text-white" />
        </button>
      </form>
    </div>
  );
};

export default SmartTextPanel;
