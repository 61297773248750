import React, { useState } from "react";
import TopHeaderComponent from "../../TopHeaderComponent";
import useGroups from "../hooks/useGroups";
import { FaCheckCircle } from "react-icons/fa";

const NewGroup = (props) => {
  const [certificateName, setCertificateName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [errors, setErrors] = useState({});
  const { create } = useGroups();
  const templates = props.certificates || [];

  const validateForm = () => {
    const newErrors = {};
    if (!certificateName.trim()) {
      newErrors.name = "Group name is required.";
    }
    if (!selectedDesign) {
      newErrors.design = "You must select a certificate design.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateCertificate = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    create({
      name: certificateName,
      certificate_id: selectedDesign.id,
      description: description,
    });

    // Navigate to groups list
    window.location.href = "/users/groups";
  };

  const handleSelectDesign = (design) => {
    setSelectedDesign(design);
    setErrors((prevErrors) => ({ ...prevErrors, design: null }));
  };

  const handleCancel = () => {
    window.location.href = "/users/groups";
  };

  return (
    <>
      <TopHeaderComponent name="New Group" />
      <div className="max-w-4xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg p-8">
          {templates.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-16">
              <h2 className="text-2xl font-semibold text-gray-700 text-center mb-4">
                No Certificate Templates Available
              </h2>
              <p className="text-gray-500 text-center mb-6">
                To create a group, you need to add or publish certificate templates first.
              </p>
              <button
                type="button"
                onClick={() => (window.location.href = "/new")}
                className="py-2 px-6 bg-blue-600 text-white font-medium rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Add Template
              </button>
            </div>
          ) : (
            <form onSubmit={handleCreateCertificate} className="space-y-8">
              {/* Group Name Input */}
              <div>
                <label
                  htmlFor="certificateName"
                  className="block text-lg font-medium text-gray-700"
                >
                  Group Name
                </label>
                <input
                  id="certificateName"
                  type="text"
                  value={certificateName}
                  onChange={(e) => {
                    setCertificateName(e.target.value);
                    setErrors((prev) => ({ ...prev, name: null }));
                  }}
                  placeholder="Enter group name"
                  className={`mt-2 block w-full p-3 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 ${errors.name
                    ? "border-red-500 focus:border-red-500 focus:ring-red-500"
                    : "border-gray-300"
                    }`}
                  aria-describedby="certificateNameError"
                />

                <label
                  htmlFor="GroupDescription"
                  className="block text-lg font-medium text-gray-700 mt-4"
                >
                  Description
                </label>
                <textarea
                  id="GroupDescription"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setErrors((prev) => ({ ...prev, name: null }));
                  }}
                  placeholder="Enter group description"
                  className={`mt-2 block w-full p-3 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 ${errors.name
                    ? "border-red-500 focus:border-red-500 focus:ring-red-500"
                    : "border-gray-300"
                    }`}
                  aria-describedby="certificateNameError"
                />
                {errors.name && (
                  <p
                    id="certificateNameError"
                    className="text-sm text-red-500 mt-1"
                  >
                    {errors.name}
                  </p>
                )}
              </div>

              {/* Certificate Design Selection */}
              <div>
                <h3 className="text-lg font-semibold text-gray-700 mb-4">
                  Select a Certificate Design
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {templates.map((design) => (
                    <div
                      key={design.id}
                      className={`relative p-4 border rounded-lg shadow-sm transition-transform transform hover:scale-105 cursor-pointer ${selectedDesign?.id === design.id
                        ? "border-blue-500 ring-2 ring-blue-500 shadow-lg bg-blue-50"
                        : "border-gray-300"
                        }`}
                      onClick={() => handleSelectDesign(design)}
                      aria-selected={selectedDesign?.id === design.id}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="aspect-w-16 aspect-h-9 rounded-t-lg overflow-hidden">
                        <img
                          src={design.cover_image}
                          alt={design.name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <p
                        className={`text-center text-lg mt-2 font-semibold ${selectedDesign?.id === design.id
                          ? "text-blue-600"
                          : "text-gray-800"
                          }`}
                      >
                        {design.name}
                      </p>
                      {selectedDesign?.id === design.id && (
                        <FaCheckCircle className="absolute top-2 right-2 text-blue-600 w-6 h-6" />
                      )}
                    </div>
                  ))}
                </div>
                {errors.design && (
                  <p className="text-sm text-red-500 mt-2">{errors.design}</p>
                )}
              </div>

              {/* Action Buttons */}
              <div className="flex justify-end items-center space-x-4 mt-8">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="py-2 px-6 bg-gray-200 text-gray-700 font-medium rounded-lg shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`py-2 px-6 font-medium rounded-lg shadow transition-colors ${certificateName.trim() && selectedDesign
                    ? "bg-blue-600 text-white hover:bg-blue-700"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}
                  disabled={!certificateName.trim() || !selectedDesign}
                >
                  Create Group
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default NewGroup;
