// src/components/utils/constants.js

export const A4_WIDTH = 1122;
export const A4_HEIGHT = 794;

export const graphics = [
  { id: 1, name: 'Template 1', image: '/graphics/olive-wreath-icon.svg' },
  { id: 2, name: 'Template 2', image: '/graphics/ribbon.svg' },
  { id: 3, name: 'Template 3', image: '/graphics/graduation-cap.svg' },
  { id: 4, name: 'Template 3', image: '/graphics/circle.svg' },
  { id: 5, name: 'Template 3', image: '/graphics/square.svg' },
  { id: 6, name: 'Template 3', image: '/graphics/line.svg' },
  { id: 7, name: 'Template 3', image: '/graphics/horizontal-line.svg' },
];

export const cert_frames = [
  { name: "Frame1", image: "/frames/01.jpg" },
  { name: "Frame1", image: "/frames/02.jpg" },
  { name: "Frame1", image: "/frames/03.jpg" },
  { name: "Frame1", image: "/frames/04.jpg" },
  { name: "Frame1", image: "/frames/05.jpg" },
];
