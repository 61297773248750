import React, { useState, useEffect } from "react";
import { FaTrash, FaCopy } from "react-icons/fa";
import HeaderComponent from "../../Header";
import { LazyLoadImage } from "react-lazy-load-image-component";

const App = (props) => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate fetch delay
    setTimeout(() => {
      const sortedCertificates = [...props.certificates].sort((a, b) =>
        new Date(b.created_at) - new Date(a.created_at) // Descending order: recent first
      );
      setCertificates(sortedCertificates);
      setLoading(false);
    }, 1000);
  }, [props]);

  return (
    <div className="bg-gray-50 min-h-screen">
      <HeaderComponent />
      <div className="container mx-auto py-8 px-4">
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {Array(6)
              .fill()
              .map((_, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-lg animate-pulse"
                >
                  <div className="aspect-w-16 aspect-h-9 bg-gray-200 rounded-t-lg"></div>
                  <div className="p-6">
                    <div className="h-6 bg-gray-200 rounded mb-4"></div>
                    <div className="h-4 bg-gray-200 rounded"></div>
                  </div>
                </div>
              ))}
          </div>
        ) : certificates.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {certificates.map((design) => (
              <a
                key={design.id}
                href={`/designs/${design.id}`}
                className="bg-white shadow-lg rounded-lg hover:shadow-2xl transition-transform transform hover:scale-105 relative"
                style={{ textDecoration: "none" }}
              >
                <div className="aspect-w-16 aspect-h-9 rounded-t-lg overflow-hidden">
                  <LazyLoadImage
                    alt={design.name}
                    className="w-full h-full object-cover"
                    height={"100%"}
                    width={"100%"}
                    src={design.cover_image || "/placeholder.png"}
                  />
                </div>
                <div className="p-6">
                  <h2 className="text-xl font-bold text-gray-800 mb-2 truncate">
                    {design.name || "Untitled"}
                  </h2>
                </div>
                <span
                  className={`absolute top-2 right-2 px-3 py-1 text-sm font-medium rounded-full ${design.published
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                    }`}
                >
                  {design.published ? "Published" : "Unpublished"}
                </span>
              </a>
            ))}
          </div>
        ) : (
          <div className="text-center py-20">
            <h2 className="text-2xl font-semibold text-gray-700">
              No certificates available
            </h2>
            <p className="text-gray-500 mt-4">
              Add some designs to get started!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
