import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      {children}
      <ToastContainer />
    </div>
  );
}
export default Layout;