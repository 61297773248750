// src/components/Canvas.js

import React from 'react';
import { FabricJSCanvas } from 'fabricjs-react';

const Canvas = ({ handleOnReady }) => {
  return (
    <div style={{ display: 'grid', placeItems: 'center' }}>
      <FabricJSCanvas
        className="sample-canvas"
        onReady={handleOnReady}
        style={{
          width: '100%',
          height: '780px',
          backgroundColor: ""
        }}
      />
    </div>
  );
};

export default Canvas;
