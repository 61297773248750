// AdminDashboard.js
import React, { useState } from 'react';
import Layout from '../Layout';
import { FaLink } from 'react-icons/fa';
const AdminDashboard = (props) => {
  const [users, setUsers] = useState(props.users);

  return (
    <Layout>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold mb-4">User List</h2>
        <div className="mb-4">
          <a
            href='/account/new_user'
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add User
          </a>
        </div>
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          {users.length > 0 ? (
            users.map((user) => (
              <div
                key={user.id}
                className="flex items-center justify-between p-4 border-b last:border-b-0 hover:bg-gray-50"
              >
                <div>
                  <h3 className="text-lg font-medium text-gray-800">{user.username}</h3>
                  <p className="text-gray-500">{user.email}</p>
                </div>



                <div className='flex gap-5'>
                  <a
                    target='_blank'
                    href={`/account/login/${user.id}`}
                    className="flex p-1 bg-blue-500 text-white px-3 py-1 rounded hover:bg-red-600"
                  >
                    Login Link
                  </a>
                  <a
                    target='_blank'
                    href={`/account/login/${user.id}`}
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-red-600"
                  >
                    Login
                  </a>

                </div>
              </div>
            ))
          ) : (
            <p className="p-4 text-center text-gray-500">No users found.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
