import React, { useEffect } from "react";
import TopHeaderComponent from '../../TopHeaderComponent';
import { FaPlus } from "react-icons/fa";
import useGroups from "../hooks/useGroups";

const GroupedRecipients = (props) => {
  const { loading, fetchGroups } = useGroups();
  const groups = props.groups || [];

  useEffect(() => {
    fetchGroups();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="text-2xl text-gray-800">Loading...</div>
      </div>
    );
  }

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8">
      <TopHeaderComponent name="Recipient Groups" />
      <div className="min-h-screen py-8">
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-2xl font-bold text-gray-800">Recipient Groups</h1>
          <a
            href="/users/groups/new"
            className="inline-flex items-center px-5 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105"
          >
            <FaPlus className="mr-2" />
            <span>Create Group</span>
          </a>
        </div>

        <div className="overflow-x-auto shadow-sm rounded-lg bg-white">
          <table className="min-w-full border border-gray-200 rounded-lg">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-600">
                  Image
                </th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-600">
                  Group Name
                </th>

                <th className="px-6 py-3 text-left text-sm font-medium text-gray-600">
                  Group Id
                </th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-600">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {groups.map((group, index) => (
                <tr
                  key={group.id}
                  className={`cursor-pointer hover:bg-gray-50 transition ${index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    }`}
                  onClick={() => (window.location.href = `/users/groups/${group.id}`)}
                >
                  <td className="px-6 py-4">
                    <img
                      src={group.image_url || 'https://via.placeholder.com/64'}
                      alt={group.name}
                      className="w-12 h-12 rounded-md object-cover"
                    />
                  </td>
                  <td className="px-6 py-4 text-gray-800 font-medium">
                    {group.name}
                  </td>
                  <td className="px-6 py-4 text-gray-800 font-medium">
                    {group.uuid}
                  </td>
                  <td className="px-6 py-4 text-right">
                    <a
                      href={`/users/groups/${group.id}`}
                      className="text-blue-600 hover:underline"
                      onClick={(e) => e.stopPropagation()} // Prevent row click conflict
                    >
                      View Details →
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GroupedRecipients;
