import TopHeaderComponent from '../../TopHeaderComponent';
import React, { useState, useEffect } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import useDesigns from '../hooks/useDesigns';
import Sidebar from './components/Sidebar';
import Toolbar from './components/Toolbar';
import useEditor from '../hooks/useEditor';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = (props) => {
  const { editor, onReady } = useFabricJSEditor({});
  const [fontSize, setFontSize] = useState(24);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontColor, setFontColor] = useState("#000000");
  const [activeMenu, setActiveMenu] = useState('Templates');
  const [paperSize, setPaperSize] = useState('A4');
  const [orientation, setOrientation] = useState('Portrait');
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const {
    templates = [],
    draft,
    uploadBg,
    bgImage = null,
    update,
    fetch,
    publish,
    loading,
    fetchTemplates,
    openPdf,
    openImage,
    uploadImage,
  } = useDesigns();

  const [textObjects, setTextObjects] = useState([]);
  const [graphicObjects, setGraphicObjects] = useState([]);
  const [dynamicTextObjects, setDynamicTextObjects] = useState([]);
  const [dynamicText, setDynamicText] = useState('');
  const [currentTemplate, setCurrentTemplates] = useState([]);

  const {
    addDynamicText,
    addText,
    handleSendToBack,
    addSvgImage,
    handleBringToFront,
    handleCopy,
    handleDelete,
    handleBgSelection,
    handleBackgroundImageUpload,
    handleOnReady,
    handleTemplateClick,
    handleSendToBackwards,
    handleRotateLeft,
    handleRotateRight,
    handleTextAlign,
  } = useEditor({
    fontColor,
    fontFamily,
    editor,
    uploadBg,
    bgImage,
    id: props.id,
    setFontColor,
    setFontSize,
    setFontFamily,
    onReady,
    certificate: props.certificate,
    update,
    setTextObjects,
    setDynamicTextObjects,
    fontSize,
  });

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (draft) {
      setHasPendingChanges(true);
    }
  }, [draft]);

  useEffect(() => {
    setCurrentTemplates([...templates]);
  }, [templates]);

  // Track changes on the canvas
  useEffect(() => {
    if (editor?.canvas) {
      const handleCanvasChange = () => setHasPendingChanges(true);

      const debouncedHandleCanvasChange = debounce(handleCanvasChange, 300);

      editor.canvas.on('object:modified', debouncedHandleCanvasChange);
      editor.canvas.on('object:added', debouncedHandleCanvasChange);
      editor.canvas.on('object:removed', debouncedHandleCanvasChange);
      editor.canvas.on('text:changed', debouncedHandleCanvasChange);

      return () => {
        editor.canvas.off('object:modified', debouncedHandleCanvasChange);
        editor.canvas.off('object:added', debouncedHandleCanvasChange);
        editor.canvas.off('object:removed', debouncedHandleCanvasChange);
        editor.canvas.off('text:changed', debouncedHandleCanvasChange);
      };
    }
  }, [editor]);

  // Warn user before leaving the page with unsaved changes
  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     if (hasPendingChanges) {
  //       e.preventDefault();
  //       const customFields = [
  //         'id',
  //         'name',
  //         'selectable',
  //         'evented',
  //         'dynamic',
  //         'editable'
  //       ]
  //       update({ id: props.id, data: editor.canvas.toJSON(customFields) });
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [hasPendingChanges]);

  const handleSave = () => {
    publish({ id: props.id });
    setHasPendingChanges(false);
  };

  return (
    <>

      <div className="text-white mx-auto px-10" style={{ width: '100%' }}>
        <TopHeaderComponent {...props.certificate} />


        <div className="flex w-full mt-1">
          {/* Sidebar for Tools */}
          <Sidebar
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            templates={currentTemplate}
            handleTemplateClick={(template) => {
              handleTemplateClick(template);
              setHasPendingChanges(true);
            }}
            handleBgSelection={(bg) => {
              handleBgSelection(bg);
              setHasPendingChanges(true);
            }}
            handleBackgroundImageUpload={(image) => {
              handleBackgroundImageUpload(image);
              setHasPendingChanges(true);
            }}
            addSvgImage={(svg) => {
              addSvgImage(svg);
              setHasPendingChanges(true);
            }}
            textObjects={textObjects}
            addText={(text) => {
              addText(text);
              setHasPendingChanges(true);
            }}
            dynamicTextObjects={dynamicTextObjects}
            addDynamicText={(text) => {
              addDynamicText(text);
              setHasPendingChanges(true);
            }}
            dynamicText={dynamicText}
            setDynamicText={setDynamicText}
            paperSize={paperSize}
            setPaperSize={setPaperSize}
            orientation={orientation}
            setOrientation={setOrientation}
            editor={editor}
            graphicObjects={graphicObjects}
            setGraphicObjects={setGraphicObjects}
            uploadImage={(image) => {
              uploadImage(image);
              setHasPendingChanges(true);
            }}
          />

          {/* Main Editor Canvas */}
          <div className="flex-1">
            <Toolbar
              fontColor={fontColor}
              fontSize={fontSize}
              fontFamily={fontFamily}
              setFontColor={setFontColor}
              setFontFamily={setFontFamily}
              setFontSize={setFontSize}
              handleBringToFront={handleBringToFront}
              handleSendToBack={handleSendToBack}
              handleDelete={handleDelete}
              handleCopy={handleCopy}
              openPdf={() => openPdf({ id: props.id })}
              openImage={() => openImage({ id: props.id })}
              publish={() => {
                publish({ id: props.id });
                setHasPendingChanges(false);
              }}
              handleSave={handleSave}
              id={props.id}
              hasPendingChanges={hasPendingChanges}
              handleRotateLeft={handleRotateLeft}
              handleRotateRight={handleRotateRight}
              handleSendToBackwards={handleSendToBackwards}
              handleTextAlign={handleTextAlign}
            />
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <FabricJSCanvas
                className="sample-canvas"
                onReady={(canvas) => handleOnReady(canvas)}
                style={{
                  width: '100%',
                  height: '780px',
                  backgroundColor: '',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

// Debounce function to limit how often a function can fire
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default App;
