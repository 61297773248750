// Layout.js
import React from 'react';
import useAccount from './hooks/useAccount';

const Header = () => {
  const { logout } = useAccount()
  return (
    <div className="bg-white 
          border-b
          border-gray-200
          p-4
          flex
          justify-between 
          items-center">
      <div className="flex items-center space-x-4">
        <a href="/accounts" className="text-2xl font-bold text-blue-600">
          <img src="/images/logo.png" alt="Create My Certificate" className="h-10" />
        </a>
      </div>

      <div className='flex gap-4'>
        <a
          href='/account/settings'
          className="bg-blue-500
          text-white
          px-4
          py-2 
          rounded"
        >
          Token
        </a>
        <button className="bg-blue-500
          text-white
          px-4
          py-2 
          rounded"
          onClick={(e) => {
            e.preventDefault()
            logout()
          }}>
          Log Out
        </button>
      </div>

    </div >
  )
};

const Layout = ({ children }) => (
  <div className="flex">
    <div className="flex-1 flex flex-col">
      <Header />
      <main className="flex-1 bg-gray-100 p-4">
        {children}
      </main>
    </div>
  </div>
);

export default Layout;
