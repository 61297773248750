import React from 'react';

const TemplatesPanel = ({ templates, handleTemplateClick }) => {
  return (
    <div className="mt-2 p-4 border border-gray-300 rounded-lg bg-white shadow-sm">
      <div className="mt-6 w-full p-0 overflow-y-auto" style={{ maxHeight: '40vh' }}>
        <div className="grid grid-cols-2 gap-6">
          {templates.map((template, index) => (
            <div
              draggable={false}
              key={index}
              className="cursor-pointer w-full h-full overflow-hidden rounded-lg shadow-md duration-200 bg-gray-50 flex flex-col items-center justify-center"
              onClick={() => {
                confirm('Are you sure you want to use this template?') && handleTemplateClick(template);
              }}
            >
              <img src={template.cover_image} alt={template.name} loading="lazy" draggable={false} />
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default TemplatesPanel;
