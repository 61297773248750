// src/components/panels/FramePanel.js

import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BlockPicker, ChromePicker } from 'react-color';
import { HexColorPicker } from "react-colorful";

const frames = require("../../../utils/frames.json")

const FramePanel = ({
  handleBgSelection,
  handleBackgroundImageUpload,
  handleBackgroundChange,
  bgImage = null
}) => {
  const [backgroundImage, setBackgroundImage] = useState(bgImage);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');


  useEffect(() => {
    setBackgroundImage(bgImage);
    console.log("BG IMAGE IS", bgImage)
  }, [bgImage]);
  return (
    <Tabs>
      <TabList>
        <Tab>Upload</Tab>

        <Tab>Choose Frame</Tab>
      </TabList>
      <TabPanel>
        <div className="p-4 border border-gray-300 rounded-md bg-white shadow-sm max-w-sm">

          {/* Display Uploaded Image */}
          {backgroundImage && (
            <div className="mb-2 relative group">
              <img
                src={backgroundImage}
                alt="Uploaded Background"
                className="w-full h-32 object-cover rounded-md border border-gray-300 shadow-sm"
              />
              <button
                onClick={() => setBackgroundImage(null)}
                className="absolute top-1 right-1 bg-red-500 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                Remove
              </button>
            </div>
          )}

          {/* Background Image Upload Section */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Upload Image</label>
            <input
              type="file"
              accept="image/*,image/svg+xml"
              onChange={(e) => handleBackgroundImageUpload(e)}
              className="w-full text-sm text-gray-500 mt-1
        file:py-1 
        file:px-2 
        file:rounded 
        file:border 
        file:border-gray-300 
        file:text-gray-700 
        file:bg-gray-100 
        file:hover:bg-gray-200 
        file:font-medium 
        cursor-pointer"
            />
            <p className="text-xs text-gray-500 mt-1">
              JPG, PNG, SVG. Max size: 2MB.
            </p>
          </div>

          {/* Background Color Picker Section */}
          <div >
            <ChromePicker
              color={backgroundColor}
              triangle="hide"
              onChangeComplete={(color) => {
                setBackgroundColor(color.hex);
                handleBackgroundChange(color.hex);
              }}
              inp
              styles={{
                default: {
                  fields: {
                    display: "none", // Hides all other fields
                  },
                  hex: {
                    display: "block", // Ensures hex field is visible
                  },
                  picker: {
                    width: '100%', // Adjust width to your desired size
                    boxShadow: 'none',

                  },
                },
              }}
            />
          </div>
        </div>

      </TabPanel>

      <TabPanel>
        <div className="p-4 border border-gray-300 rounded-lg bg-white shadow-sm">
          <div className="mt-6 w-full p-0 overflow-y-auto" style={{ maxHeight: '40vh' }}>
            <div className="grid grid-cols-2 gap-6">
              {frames.map((frame, index) => (
                <div
                  draggable={false}
                  key={index}
                  className="cursor-pointer w-full h-full overflow-hidden rounded-lg shadow-md duration-200 bg-gray-50 flex flex-col items-center justify-center"
                  onClick={() => handleBgSelection(frame.image)}
                >
                  <img src={frame.image} alt={frame.name} loading="lazy" draggable={false} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </TabPanel>

    </Tabs>
  );
};

export default FramePanel;
