import React, { useState } from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
  FaTelegram,
} from "react-icons/fa";

const CertificatePage = ({
  recipient = null,
  group = null,
}) => {
  const [hovered, setHovered] = useState(false);

  const certificateDetails = {
    credentialId: recipient.uuid,
    certificateImage: recipient.png,
  };
  const shareUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4 py-8">
      {/* Certificate Wrapper */}
      <div
        className="bg-white rounded-lg shadow-2xl max-w-4xl w-full p-8 border border-gray-200 relative transition-transform duration-300 "
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {/* Certificate Content */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-semibold text-blue-800 mt-4">
            {group.name}
          </h1>
        </div>

        {/* Certificate Image */}
        <div className="relative">
          <img
            src={certificateDetails.certificateImage}
            alt="Certificate"
            className="rounded-lg border border-gray-300 shadow-lg max-w-full"
          />
          {hovered && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center transition-opacity duration-300">
              <h3 className="text-white text-lg font-semibold mb-4">
                Share Your Achievement
              </h3>
              <div className="flex space-x-4">
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-700 p-3 rounded-full hover:bg-blue-800 transition"
                  aria-label="Share on LinkedIn"
                >
                  <FaLinkedinIn className="text-xl" />
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=Check out my achievement!`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-500 p-3 rounded-full hover:bg-blue-600 transition"
                  aria-label="Share on Twitter"
                >
                  <FaTwitter className="text-xl" />
                </a>
                <a
                  href={`https://wa.me/?text=Check out my achievement: ${shareUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-green-500 p-3 rounded-full hover:bg-green-600 transition"
                  aria-label="Share on WhatsApp"
                >
                  <FaWhatsapp className="text-xl" />
                </a>
                <a
                  href={`https://telegram.me/share/url?url=${shareUrl}&text=Check out my achievement!`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-500 p-3 rounded-full hover:bg-blue-600 transition"
                  aria-label="Share on Telegram"
                >
                  <FaTelegram className="text-xl" />
                </a>
              </div>
            </div>
          )}
        </div>

        {/* Metadata Section */}
        <div className="mt-8 border-t border-gray-300 pt-4 text-center">

          <p className="text-gray-400 text-xs mt-1">
            ID: {certificateDetails.credentialId}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CertificatePage;
