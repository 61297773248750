import React, { useState } from "react";
import useRecipients from "../hooks/useRecipients";
import TopHeaderComponent from "../../TopHeaderComponent";

const NewRecipient = ({ fields = [], id }) => {
  const [errors, setErrors] = useState({});
  const { create } = useRecipients();
  const [email, setEmail] = useState("");
  const [data, setData] = useState({});



  const handleCreateRecipient = (e) => {
    e.preventDefault();


    create(
      {
        email,
        data,
        id,
      }
    )
    console.log(data);
  };

  return (
    <>
      <TopHeaderComponent />
      <div className="flex justify-center mt-10 px-4 sm:px-0">
        <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
            Add a New Recipient
          </h2>
          <form onSubmit={handleCreateRecipient} className="space-y-6">
            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email (Optional)
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter email address"
                className={`mt-1 block w-full p-3 border rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm ${errors.email
                  ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                  : "border-gray-300"
                  }`}
                aria-label="Email"
              />

            </div>
            {
              fields.map((field, index) => {
                {/* First Name */ }
                return <div key={index}>
                  <label
                    htmlFor={field}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {field}
                  </label>
                  <input
                    id={field}
                    type="text"
                    value={data.field}
                    onChange={(e) => {
                      setData({ ...data, [field]: e.target.value });
                    }}
                    placeholder="Enter first name"
                    className={`mt-1 block w-full p-3 border rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm ${errors.first_name
                      ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                      : "border-gray-300"
                      }`}
                    aria-label="First Name"
                  />
                </div>
              })
            }





            {/* Buttons */}
            <div className="flex justify-between space-x-4">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
              >
                Create Recipient
              </button>
              <button
                type="button"
                onClick={() => (window.location.href = "/recipients")}
                className="w-full py-2 px-4 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewRecipient;
