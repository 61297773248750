import { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

const useRecipients = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRecipients = () => {


    setLoading(true);
    axios.get(`/users/groups/`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //update the template 
  const update = ({ id = null, data }) => {

    if (!id) {
      return;
    }
    setLoading(true);
    axios.patch(`/users/certificates/${id}`,
      { data },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Create a new certificate
  const create = ({
    email,
    data,
    id
  }) => {
    setLoading(true);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    axios.post(`/users/groups/${id}/new_recipient`,
      { data, email },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 201) {
          console.log("Login successful");
          // Redirect to the dashboard
          window.location.href = `/users/groups/${id}`;
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  return {
    groups,
    create,
    loading,
    update,
    fetchRecipients
  };
}

export default useRecipients;  