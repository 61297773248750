import { FaList, FaPlus, FaSignOutAlt, FaBars } from 'react-icons/fa';
import { GoGear } from 'react-icons/go';
import React, { useState } from 'react';
import useLogin from './users/hooks/useLogin';

const MenuItem = ({ href, onClick, icon: Icon, label }) => (
  <a
    href={href}
    onClick={onClick}
    className="flex items-center px-4 py-2 text-gray-700 hover:text-blue-600"
  >
    <Icon className="mr-2" />
    {label}
  </a>
);

const HeaderComponent = () => {
  const { logout } = useLogin(); // Assume useLogin provides the user info
  const [menuOpen, setMenuOpen] = useState(false);

  const menuItems = [
    { href: '/new', icon: FaPlus, label: 'Create Design' },
    { href: '/users/groups', icon: FaList, label: 'Recipients' },
    { href: '/users/settings', icon: GoGear, label: 'Settings' },
  ];

  return (
    <nav className="bg-white shadow-md p-4 mb-10">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <div>
          <a href="/" className="text-2xl font-bold text-blue-600 flex items-center">
            <img src="/images/logo.png" alt="Create My Certificate" className="h-8" />
          </a>
        </div>

        {/* Hamburger Menu for Mobile */}
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="md:hidden text-blue-600 focus:outline-none"
        >
          <FaBars size={24} />
        </button>

        {/* Navigation Links */}
        <div
          className={`${menuOpen ? 'block' : 'hidden'
            } absolute top-16 right-0 bg-white shadow-md rounded-lg p-4 md:p-0 md:static md:shadow-none md:block`}
        >
          <div className="flex flex-col md:flex-row md:items-center md:space-x-6">
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                href={item.href}
                icon={item.icon}
                label={item.label}
              />
            ))}

            {/* Sign Out */}
            <button
              onClick={logout}
              className="flex items-center px-4 py-2 text-gray-700 hover:text-blue-600 focus:outline-none"
            >
              <FaSignOutAlt className="mr-2" />
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderComponent;
