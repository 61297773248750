import { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

const useDesigns = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bgImage, setBgImage] = useState(null);
  const [draft, setDraft] = useState(false);

  //update the template 
  const update = ({ id = null, data }) => {

    if (!id) {
      return;
    }
    setLoading(true);
    axios.patch(`/users/certificates/${id}`,
      { data },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          setDraft(response.data.draft);
          console.log("Login successful", response);
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //update the template 
  const updateName = ({ id = null, name }) => {

    if (!id) {
      return;
    }
    setLoading(true);
    axios.patch(`/users/certificates/${id}/update_name`,
      { name },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          //Show success toast 
          toast.success("Name updated successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: null,

          });
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //fetch the templates
  const fetchTemplates = () => {
    axios.get("/users/templates", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then((response) => {
        setTemplates(response.data.templates);
      }
      );

  };

  //Create a new certificate
  const create = (name) => {
    setLoading(true);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    axios.post("/users/certificates",
      name,
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful", response.data);
          // Redirect to the dashboard
          window.location.href = response.data.redirect_url;
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Publish the design 

  const publish = ({ id }) => {
    setLoading(true)
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    axios.post(`/designs/${id}/publish`, {}, {
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }).
      then((response) => {
        if (response.status === 200) {
          console.log("OKEY")
        }
      }).catch((response) => {
        console.log("ERROR", response)
      }).finally(() => {
        setLoading(false)
      })
  }

  //Upload background image 
  const uploadBg = (formData) => {
    setLoading(true);
    axios.post(`/designs/upload_bg`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          setBgImage(response.data.url);
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Upload image 
  const uploadImage = ({ formData, addImage = null, addSvgImage = null }) => {
    setLoading(true);
    axios.post(`/designs/upload_image`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      }
    ).
      then((response) => {
        if (response.status === 200) {
          if (response.data.file_type === "svg") {
            addSvgImage({
              image: response.data.url,
              name: response.data.name
            });
          } else {
            addImage({
              url: response.data.url,
              name: response.data.name
            });
          }

        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }
  const openPdf = ({ id }) => {
    window.open(`/designs/${id}/pdf`, "self");
  }

  const openImage = ({ id }) => {
    window.open(`/designs/${id}/png`, "self");
  }

  return {
    templates,
    fetchTemplates,
    create,
    loading,
    update,
    publish,
    uploadBg,
    bgImage,
    openImage,
    openPdf,
    updateName,
    uploadImage,
    draft
  };
}

export default useDesigns;  