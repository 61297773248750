import React, { useState } from "react";
import axios from "axios";

const useAccount = () => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(false);


  const createUser = ({
    email,
    password,
  }) => {
    setLoading(true);
    axios.post("/account/new_user",
      {
        user: {
          email,
          password
        }
      },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        console.log("RESPONSE")
        window.location.href = "/accounts";

      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }


  const logout = () => {
    setLoading(true);
    axios.delete("/accounts/sign_out",
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        console.log("RESPONSE")
        window.location.href = "/accounts";

      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Fetch token

  const getToken = () => {
    setLoading(true);
    axios.get("/accounts/token", {
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      setToken(response.data.token);
    }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }
  return { loading, logout, getToken, token, createUser };
}
export default useAccount;