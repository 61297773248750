// src/components/panels/SettingsPanel.js

import React from 'react';

const SettingsPanel = ({
  paperSize,
  setPaperSize,
  orientation,
  setOrientation,
}) => {
  return (
    <div className="mt-2 p-4 border border-gray-300 rounded-md bg-white">
      <h3 className="text-lg font-medium text-gray-700 mb-4">Format</h3>
      <div className="flex flex-col gap-4">
        <div>
          <label className="text-sm text-gray-800 font-medium">Paper Size:</label>
          <select
            className="w-full p-2 mt-1 border rounded-md"
            value={paperSize}
            onChange={(e) => setPaperSize(e.target.value)}
          >
            <option value="A4">A4</option>
            <option value="US Letter">US Letter</option>
          </select>
        </div>
        <div>
          <label className="text-sm text-gray-800 font-medium">Orientation:</label>
          <select
            className="w-full p-2 mt-1 border rounded-md"
            value={orientation}
            onChange={(e) => setOrientation(e.target.value)}
          >
            <option value="Portrait">Portrait</option>
            <option value="Landscape">Landscape</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;
