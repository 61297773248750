// AdminDashboard.js
import React, { useState } from 'react';
import Layout from '../Layout';
import useAccount from '../hooks/useAccount';
const NewUser = (props) => {
  const { createUser } = useAccount();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    rePassword: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addUser = () => {
    if (formData.password !== formData.rePassword) {
      alert("Passwords don't match!");
      return;
    }
    const newUser = {
      email: formData.email,
      password: formData.password,

    };
    createUser(newUser);

    setFormData({ name: '', email: '', password: '', rePassword: '' }); // Reset form
  };


  return (
    <Layout>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold mb-4">Add New User</h2>
        <div className="bg-white shadow-md rounded-lg p-4 mb-6">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addUser();
            }}
          >

            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-1">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-1">Re-enter Password</label>
              <input
                type="password"
                name="rePassword"
                value={formData.rePassword}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Add User
            </button>
          </form>
        </div>


      </div>
    </Layout>
  );
};

export default NewUser;
