import React from "react";
import TopHeaderComponent from "../../TopHeaderComponent";
import {
  FaUserPlus,
  FaFileImport,
  FaFileDownload,
  FaFilePdf,
  FaImage,
  FaLink,
} from "react-icons/fa";
import useGroups from "../hooks/useGroups";

const GroupDetailsPage = ({ recipients = [], group = null }) => {
  if (!group) return null;

  const { uploadCsv, loading } = useGroups();

  const handleFileUpload = ({ target }) => {
    const file = target.files[0];
    if (!file) return; // Early return if no file

    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", group.id);
      uploadCsv(formData);

      // Reset input value
      target.value = null;
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    reader.readAsDataURL(file);
  };

  if (loading) {
    return (
      <div className="text-gray-800 mx-auto px-4 sm:px-6 lg:px-8">
        <TopHeaderComponent name={group.name} />
        <div className="rounded-lg min-h-screen bg-gray-50 p-6">
          <div className="bg-white shadow rounded-lg p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="md:col-span-1">
              <div className="animate-pulse bg-gray-200 h-96 rounded-lg"></div>
            </div>
            <div className="md:col-span-2">
              <div className="animate-pulse bg-gray-200 h-8 w-1/2 rounded mb-4"></div>
              <div className="animate-pulse bg-gray-200 h-8 w-1/2 rounded mb-4"></div>
              <div className="animate-pulse bg-gray-200 h-8 w-1/2 rounded mb-4"></div>
              <div className="animate-pulse bg-gray-200 h-8 w-1/2 rounded mb-4"></div>
              <div className="animate-pulse bg-gray-200 h-8 w-1/2 rounded mb-4"></div>
              <div className="animate-pulse bg-gray-200 h-8 w-1/2 rounded mb-4"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="text-gray-800 mx-auto px-4 sm:px-6 lg:px-8">
      <TopHeaderComponent name={group.name} />
      <div className="rounded-lg min-h-screen bg-gray-50 p-6">
        {/* Certificate Design */}
        <div className="mb-12">
          <h3 className="text-2xl font-bold text-gray-800 mb-6">Certificate Design</h3>
          <div className="bg-white shadow rounded-lg p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Design Image */}
            <div className="md:col-span-1">
              <img
                src={group.image_url}
                alt="Certificate Design"
                className="w-full h-auto object-cover border border-gray-200 rounded-lg"
              />
            </div>

            {/* Design Details */}
            <div className="md:col-span-2">
              <h4 className="text-xl font-semibold text-gray-800 mb-2">Design Name</h4>
              <p className="text-gray-600 mb-4">{group.name}</p>

              <h4 className="text-xl font-semibold text-gray-800 mb-2">Dynamic Fields</h4>
              {group.fields?.length > 0 ? (
                <ul className="list-disc list-inside text-gray-600 space-y-1">
                  {group.fields.map((field, index) => (
                    <li key={index}>{field}</li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500">No dynamic fields added yet.</p>
              )}
            </div>
          </div>
        </div>

        {/* Recipients Section */}
        <div>
          <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4 md:mb-0">Recipients</h2>
            <div className="flex flex-wrap gap-4">
              <a
                href={`/users/groups/${group.id}/new_recipient`}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 transition"
                aria-label="Add Recipient"
              >
                <FaUserPlus />
                <span>Add Recipient</span>
              </a>

              <a
                href={`/users/groups/${group.id}/sample_csv`}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 transition"
                aria-label="Download Sample CSV"
              >
                <FaFileDownload />
                <span>Sample CSV</span>
              </a>
              <label className="bg-green-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 transition cursor-pointer">
                <FaFileImport />
                <span>Upload CSV</span>
                <input
                  type="file"
                  accept=".csv"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg">
            {recipients.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Uuid
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {recipients.map((awardee) => (
                    <tr key={awardee.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{awardee.uuid}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{awardee.email || "-"}</div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="inline-flex gap-2">

                          {
                            awardee.url_token && <a
                              href={`/certificates/${awardee.url_token}`}
                              className="flex items-center text-blue-600 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-300 p-2 rounded-md shadow-sm transition"
                              aria-label={`View Image for ${awardee.name}`}
                              target="_blank"
                            >
                              <FaLink className="mr-1" />
                              <span>Link</span>
                            </a>
                          }

                          {
                            awardee.png && <a
                              href={awardee.png}
                              className="flex items-center text-blue-600 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-300 p-2 rounded-md shadow-sm transition"
                              aria-label={`View Image for ${awardee.png}`}
                              target="_blank"
                            >
                              <FaImage className="mr-1" />
                              <span>Image</span>
                            </a>
                          }
                          {
                            awardee.pdf && <a
                              target="_blank"
                              href={awardee.pdf}
                              className="flex items-center text-red-600 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-300 p-2 rounded-md shadow-sm transition"
                              aria-label={`Download PDF for ${awardee.name}`}
                            >
                              <FaFilePdf className="mr-1" />
                              <span>PDF</span>
                            </a>
                          }

                        </div>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="p-6 text-center text-gray-500">
                No recipients added yet. <br />
                <a
                  href={`/users/groups/${group.id}/new_recipient`}
                  className="text-blue-500 hover:underline"
                >
                  Add a new recipient
                </a>{" "}
                to get started.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailsPage;
