'use strict';

import React, { useState, useEffect } from 'react';
import reactCSS from 'reactcss';
import { BlockPicker } from 'react-color';

const SketchExample = ({ value, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState("#f17013");

  useEffect(() => {
    if (value) {
      setColor(value);
    }
  }, [value]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    if (onChange) {
      onChange(newColor.hex);
    }
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '34px',
        borderRadius: '0',
        background: color,
      },
      swatch: {
        padding: '1px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        top: '33px',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div>
      <div className="flex items-center space-x-2" onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <div
            className="relative"
            style={{
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
              marginTop: '5px',
            }}
          >
            <BlockPicker color={color} onChange={handleChange} triangle='hide' />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SketchExample;
