import React from "react";
import {
  RiBringToFront,
  RiSendToBack,
} from "react-icons/ri";
import {
  FaTrash,
  FaCopy,
  FaFilePdf,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaLayerGroup
} from "react-icons/fa";
import {
  AiOutlineRotateLeft,
  AiOutlineRotateRight,
} from "react-icons/ai";
import FontSelect from "../../../utils/FontSelect";
import ToolbarColorPicker from "../../../utils/ToolbarColorPicker";

// Reusable button component
const ToolbarButton = ({ onClick, icon: Icon, label }) => (
  <button
    className="p-2 rounded bg-gray-100 hover:bg-gray-200 text-black"
    onClick={onClick}
    aria-label={label}
    title={label}
  >
    <Icon size={18} />
  </button>
);

const Toolbar = ({
  fontSize,
  setFontSize,
  fontFamily,
  setFontFamily,
  fontColor,
  setFontColor,
  handleBringToFront,
  handleRotateRight,
  handleRotateLeft,
  handleSendToBack,
  handleSendToBackwards,
  handleDelete,
  handleCopy,
  openPdf,
  hasPendingChanges = false,
  publish = null,
  handleTextAlign = null,
}) => {
  return (
    <div className="w-full bg-white shadow-md p-2 flex flex-wrap items-center space-x-2 mb-2">
      {/* Font Settings */}
      <div className="flex items-center space-x-2">
        <input
          id="font-size"
          value={fontSize}
          type="number"
          className="w-16 p-1 border rounded text-sm text-black"
          onChange={(e) => setFontSize(parseInt(e.target.value, 10))}
          min="1"
          title="Font Size"
        />
        <FontSelect
          id="font-family"
          value={fontFamily}
          onChange={setFontFamily}
        />
        <ToolbarColorPicker
          id="font-color"
          value={fontColor}
          onChange={setFontColor}
        />
      </div>

      {/* Divider */}
      <div className="h-6 border-l border-gray-300 mx-2"></div>

      {/* Rotate Buttons */}
      <div className="flex items-center space-x-1">
        <ToolbarButton
          onClick={handleRotateLeft}
          icon={AiOutlineRotateLeft}
          label="Rotate Left"
        />
        <ToolbarButton
          onClick={handleRotateRight}
          icon={AiOutlineRotateRight}
          label="Rotate Right"
        />
      </div>

      {/* Text Align Buttons */}
      <div className="flex items-center space-x-1">
        <ToolbarButton
          onClick={() => handleTextAlign("left")}
          icon={FaAlignLeft}
          label="Align Left"
        />
        <ToolbarButton
          onClick={() => handleTextAlign("center")}
          icon={FaAlignCenter}
          label="Align Center"
        />
        <ToolbarButton
          onClick={() => handleTextAlign("right")}
          icon={FaAlignRight}
          label="Align Right"
        />
      </div>

      {/* Divider */}
      <div className="h-6 border-l border-gray-300 mx-2"></div>

      {/* Layer Buttons */}
      <div className="flex items-center space-x-1">
        <ToolbarButton
          onClick={handleSendToBack}
          icon={FaLayerGroup}
          label="Send to Back"
        />
        <ToolbarButton
          onClick={handleBringToFront}
          icon={RiBringToFront}
          label="Bring to Front"
        />
        <ToolbarButton
          onClick={handleSendToBackwards}
          icon={RiSendToBack}
          label="Send Backwards"
        />
      </div>

      {/* Divider */}
      <div className="h-6 border-l border-gray-300 mx-2"></div>

      {/* Action Buttons */}
      <div className="flex items-center space-x-1">
        <ToolbarButton
          onClick={handleCopy}
          icon={FaCopy}
          label="Copy"
        />
        <ToolbarButton
          onClick={handleDelete}
          icon={FaTrash}
          label="Delete"
        />
      </div>

      {/* Divider */}
      <div className="h-6 border-l border-gray-300 mx-2"></div>

      {/* Export Button */}
      <ToolbarButton
        onClick={openPdf}
        icon={FaFilePdf}
        label="Preview PDF"
        className="bg-green-500 hover:bg-green-600 text-white"
      />

      {hasPendingChanges && (
        <button
          className="ml-2 p-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          onClick={publish}
        >
          Publish
        </button>
      )}
    </div>
  );
};

export default Toolbar;
