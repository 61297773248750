import { useState } from "react";
import axios from "axios";

const useTemplate = () => {

  const [loading, setLoading] = useState(false);
  const [bgImage, setBgImage] = useState(null);
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const [template, setTemplate] = useState(null);

  ///Publish a template 

  const publish = ({ id }) => {
    setLoading(true)
    axios.post(`/authors/templates/${id}/publish`).
      then((response) => {
        if (response.status === 200) {
          console.log("OKEY")
        }
      }).catch((response) => {
        console.log("ERROR", response)
      }).finally(() => {
        setLoading(false)
      })
  }

  //Fetch a single template 
  const fetch = ({ id }) => {
    setLoading(true);
    axios.get(`/authors/templates/${id}`).
      then((response) => {
        if (response.status === 200) {
          // Redirect to the dashboard
          setTemplate(response.data);
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }


  //update the template 
  const update = ({ id = null, data }) => {

    if (!id) {
      return;
    }
    setLoading(true);
    axios.patch(`/authors/templates/${id}`,
      { data },
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //Upload template background image

  const uploadBg = (formData) => {
    setLoading(true);
    axios.post(`/authors/templates/upload_bg`,
      formData,
      {
        headers: {
          "X-CSRF-Token": csrfToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          console.log("Login successful");
          // Redirect to the dashboard
          setBgImage(response.data.url);
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }


  const openPdf = ({ id }) => {
    window.open(`/authors/templates/${id}/pdf`, "self");
  }


  //Upload image 
  const uploadImage = ({ formData, addImage = null, addSvgImage = null }) => {
    setLoading(true);
    axios.post(`/authors/templates/upload_image`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      }
    ).
      then((response) => {
        if (response.status === 200) {
          if (response.data.file_type === "svg") {
            addSvgImage({
              image: response.data.url,
              name: response.data.name
            });
          } else {
            addImage({
              url: response.data.url,
              name: response.data.name
            });
          }

        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }

  //update the template  name 
  const updateName = ({ id = null, name }) => {
    if (!id) {
      return;
    }
    setLoading(true);
    axios.patch(`/authors/templates/${id}/update_name`,
      { name },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    ).
      then((response) => {
        if (response.status === 200) {
          //Show success toast 
          toast.success("Name updated successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: null,

          });
        }
      }).
      catch((error) => {
        console.log(error);
      }).
      finally(() => {
        setLoading(false);
      });
  }
  return {
    uploadImage,
    loading,
    uploadBg,
    fetch,
    update,
    bgImage,
    publish,
    openPdf,
    updateName
  };
}
export default useTemplate;