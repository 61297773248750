import React, { useState, useEffect } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import useDesigns from '../hooks/useEmbedDesigns';
import Sidebar from './components/Sidebar';
import Toolbar from './components/Toolbar';
import useEditor from '../hooks/useEditor';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const frames = require("../../utils/frames.json")

const App = (props) => {
  const { editor, onReady } = useFabricJSEditor({});
  const [fontSize, setFontSize] = useState(24);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontColor, setFontColor] = useState("#000000");
  const [activeMenu, setActiveMenu] = useState('Templates');
  const [paperSize, setPaperSize] = useState('A4');
  const [orientation, setOrientation] = useState('Portrait');
  const { templates = [],
    uploadBg,
    bgImage = null,
    update,
    fetch,
    publish,
    draft,
    fetchTemplates,
    openPdf,
    openImage,
    uploadImage
  } = useDesigns();
  const [textObjects, setTextObjects] = useState([]);
  const [dynamicTextObjects, setDynamicTextObjects] = useState([]);
  const [dynamicText, setDynamicText] = useState('');
  const [currentTemplate, setCurrentTemplates] = useState([])
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const {
    addDynamicText,
    addText,
    handleSendToBack,
    addSvgImage,
    handleBringToFront,
    handleCopy,
    handleDelete,
    handleBgSelection,
    handleBackgroundImageUpload,
    handleOnReady,
    handleTemplateClick,
    handleBackgroundChange,
    handleRotateLeft,
    handleRotateRight,
    handleTextAlign,
    handleSendToBackwards
  } = useEditor({
    fontColor,
    fontFamily,
    editor,
    uploadBg,
    bgImage,
    id: props.id,
    setFontColor,
    setFontSize,
    setFontFamily,
    onReady,
    certificate: props.certificate,
    update,
    setTextObjects,
    setDynamicTextObjects,
    fontSize
  });

  useEffect(() => {
    fetchTemplates();

    return () => {
      // Cleanup]
      alert("Cleaning up")
      publish({ id: props.id })
    }

  }, []);


  useEffect(() => {
    setCurrentTemplates(() => {
      return [...templates]
    })
  }, [templates])



  // Warn user before leaving the page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (draft) {
        // e.preventDefault();
        alert("Cleaning up")
        publish({ id: props.id })
        return 'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [draft]);

  return (
    <div className='text-white mx-auto px-10 py-5' style={{ width: "100%" }}>
      <div className="flex w-full">
        {/* Sidebar for Tools */}
        <Sidebar
          bgImage={bgImage}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          templates={currentTemplate}
          handleTemplateClick={handleTemplateClick}
          cert_frames={frames}
          handleBgSelection={handleBgSelection}
          handleBackgroundImageUpload={handleBackgroundImageUpload}
          addSvgImage={addSvgImage}
          textObjects={textObjects}
          addText={addText}
          dynamicTextObjects={dynamicTextObjects}
          addDynamicText={addDynamicText}
          dynamicText={dynamicText}
          setDynamicText={setDynamicText}
          paperSize={paperSize}
          setPaperSize={setPaperSize}
          orientation={orientation}
          setOrientation={setOrientation}
          editor={editor}
          uploadImage={uploadImage}
          handleBackgroundChange={(color) => {
            console.log(color);
            handleBackgroundChange({ color });
          }}
          key={bgImage}
        />

        {/* Main Editor Canvas */}
        <div className="flex-1">
          <Toolbar
            fontColor={fontColor}
            fontSize={fontSize}
            fontFamily={fontFamily}
            setFontColor={setFontColor}
            setFontFamily={setFontFamily}
            setFontSize={setFontSize}
            handleBringToFront={handleBringToFront}
            handleSendToBack={handleSendToBack}
            handleDelete={handleDelete}
            handleCopy={handleCopy}
            openPdf={openPdf}
            openImage={openImage}
            publish={() => {
              publish({ id: props.id })
            }}
            id={props.id}
            hasPendingChanges={draft}
            handleRotateLeft={handleRotateLeft}
            handleRotateRight={handleRotateRight}
            handleSendToBackwards={handleSendToBackwards}
            handleTextAlign={handleTextAlign}

          />
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <FabricJSCanvas
              className="sample-canvas"
              onReady={(canvas) => handleOnReady(canvas)}
              style={{
                width: '100%',
                height: '780px',
                backgroundColor: ""
              }}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
