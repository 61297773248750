import React, { useState } from 'react';
import EditorContext from './EditorContext';
import { useFabricJSEditor } from 'fabricjs-react';

const EditorProvider = ({ children }) => {
  const { editor, onReady } = useFabricJSEditor({});

  return (
    <EditorContext.Provider value={{ editor, onReady }}>
      {children}
    </EditorContext.Provider>
  );
};

export default EditorProvider;
