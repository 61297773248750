import React, { useState } from "react";
import useLogin from "../hooks/useLogin";
import { ToastContainer } from "react-toastify";
const Settings = ({ user }) => {
  const { resetPassword } = useLogin()

  const [newUserName, setNewUserName] = useState("");
  const [fakeAccessToken, setFakeAccessToken] = useState("mock-12345-abcdef");

  const currentUser = {
    email: "user@example.com",
  };

  const plan = {
    name: "Free",
    features: ["Basic support", "Limited access"],
  };

  const addUser = () => {
    if (newUserName.trim() === "") return;

    const newUser = {
      id: Math.floor(Math.random() * 10000),
      name: newUserName,
    };

    setUsers([...users, newUser]);
    setNewUserName("");
  };

  const removeUser = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  const resetAccessToken = () => {
    setFakeAccessToken(`mock-${Math.random().toString(36).substr(2, 10)}`);
  };

  const sendPasswordResetEmail = () => {
    resetPassword()
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Custom Header */}
      <header className="flex items-center justify-between py-4 border-b border-gray-200">
        <a
          href="/"
          className="text-blue-500 hover:underline text-sm font-medium"
        >
          Back to Dashboard
        </a>
        <h1 className="text-2xl font-bold">Settings</h1>

      </header>

      <div className="min-h-screen py-8 space-y-8">
        {/* User Info Section */}
        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Account Information</h2>
          <p className="text-gray-700">Email: {user.email}</p>
        </div>

        {/* Plan Section */}
        {/* <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Current Plan</h2>
          <div>
            <p className="text-lg font-medium mb-2">Plan: {plan.name}</p>
            <ul className="list-disc list-inside text-gray-700">
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <div className="mt-4">
              {plan.name === "Free" ? (
                <button
                  onClick={() => alert("Upgraded to Pro!")}
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:ring-2 focus:ring-green-300"
                >
                  Upgrade to Pro
                </button>
              ) : (
                <button
                  onClick={() => alert("Downgraded to Free!")}
                  className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-300"
                >
                  Downgrade to Free
                </button>
              )}
            </div>
          </div>
        </div> */}

        {/* Users Section */}
        {/* <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Manage Users</h2>
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <input
                type="text"
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
                placeholder="Enter user name"
                className="flex-1 border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={addUser}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:ring-2 focus:ring-blue-300"
              >
                Add User
              </button>
            </div>
            <ul className="divide-y divide-gray-200">
              {users.map((user) => (
                <li
                  key={user.id}
                  className="flex justify-between items-center py-2"
                >
                  <span>{user.name}</span>
                  <button
                    onClick={() => removeUser(user.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div> */}

        {/* Access Token Section */}
        {/* <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Access Token</h2>
          <div className="flex items-center justify-between">
            <span className="text-gray-700 break-all">{fakeAccessToken}</span>
            <button
              onClick={resetAccessToken}
              className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 focus:ring-2 focus:ring-purple-300"
            >
              Reset Token
            </button>
          </div>
        </div> */}

        {/* Password Section */}
        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Change Password</h2>
          <div className="space-y-4">
            <p className="text-gray-600">To change your password, we will send you a password reset link via email.</p>
            <button
              onClick={sendPasswordResetEmail}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:ring-2 focus:ring-blue-300"
            >
              Send Password Reset Email
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Settings;
